<template>
  <div class="healthList">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :immediateCheck="false"
      :finishedText="finishedText"
      :commonOperaParams="commonOperaParams"
      :beforeMyFunction="beforeMyFunction"
    >
      <div
        v-for="(item, index) in listData"
        :key="index"
        class="oItem"
        @click="toDetail(item)"
      >
        <div class="oItem-head">
          <!-- <div class="oItem-headName">
            <div class="oItem-avatar" v-if="item.avatar">
              <img :src="item.avatar" alt="" />
            </div>
            <div class="oItem-avatar" v-else>
              <img src="@/assets/img/default_avatar.png" alt="" />
            </div>
            <div class="oItem-tl oItem-name">
              {{ item.userName ? formatStrFunc(item.userName) : "" }}
            </div>
          </div> -->
          <div class="oItem-t">
            <span class="oItem-tr oItem-time">{{ item.insertTime }} </span>
            <span
              :class="['oItem-time', 'oItem-status', getStatusClass(item)]"
              >{{ getAuditStatus(item) }}</span
            >
          </div>
        </div>
        <div class="oItem-c" v-if="item.healthPic">
          <div
            class="item"
            v-for="(items, indexs) in item.healthPic"
            :key="indexs"
          >
            <img :src="items" alt="" />
          </div>
        </div>
        <div class="oItem-b">
          {{ item.proposalContent }}
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { myHealthUrl } from "./api.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
import { getOption, formatStr, handleImg } from "@/utils/utils.js";
import { wishType } from "./map.js";
import { auditStatusMap } from "@/views/futureNeighbourhood/wishes/map";
var moment = require("moment");
export default {
  name: "healthList",
  data() {
    return {
      commonOperaParams: {
        showMy: false,
        isCommonOpera: true,
        name: "运动打卡",
        operaUrl: "health",
        myUrl: "myWishes",
        noMyOpera: false,
        createText: "去打卡",
      },
      isOne: false,
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
  },
  async mounted() {
    if (await toRegister(this.$route.path, this.$route.query, "运动打卡")) {
      this.getWishList();
    }
  },
  methods: {
    beforeMyFunction() {
      if (this.isOne) {
        this.$toast("今日已打卡");
      }
    },
    getStatusClass(item) {
      if (item.auditStatus || item.auditStatus == 0) {
        return getOption(item.auditStatus, auditStatusMap, "key").class;
      } else {
        return "";
      }
    },
    formatStrFunc(str) {
      if (str) {
        return formatStr(str);
      }
    },
    getAuditStatus(item) {
      if (item.auditStatus || item.auditStatus == 0) {
        return getOption(item.auditStatus, auditStatusMap, "key").value;
      } else {
        return "";
      }
    },
    getStatus(item) {
      if (item.wishType) {
        return getOption(item.wishType, wishType, "key").value;
      } else {
        return "";
      }
    },
    onLoad() {
      this.getWishList();
    },
    getWishList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
      };
      this.$axios.get(`${myHealthUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data.hasTodayRecord) {
            this.isOne = true;
            this.commonOperaParams.noMyOpera = true;
          }
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.healthPic) {
              item.healthPic = item.healthPic.split(",");
              item.healthPic.forEach((items, index) => {
                items = handleImg(200, 200, items);
              });
            }
            this.listData.push(item);
          });
          this.$forceUpdate();
        } else {
        }
      });
    },
    toDetail(item) {
      this.$router.push({
        name: "healthDetail",
        query: { id: item.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.healthList {
  min-height: 100vh;
  background: #f7f7f7;
  .oItem {
    background: #fff;
    padding: 40px;
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 30px;
    .oItem-head {
      //   display: flex;
      //   justify-content: space-between;
      font-weight: bold;
      margin-bottom: 10px;
      align-items: center;
    }
    .oItem-headName {
      display: flex;
      align-items: center;
    }

    .oItem-t {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .oItem-avatar {
      width: 80px;
      height: 80px;
      /*border-radius:30px;*/
      /*border: 0.5px solid #999;*/
    }
    .oItem-avatar img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      /*border: 0.5px solid #999;*/
    }
    .oItem-name {
      margin-left: 15px;
      font-size: 32px;
      font-weight: bold;
    }
    .oItem-time {
      font-size: 28px !important;
      font-weight: normal !important;
      color: #999;
    }
    .oItem-status {
      color: #178af7;
    }
    .out,
    .reback {
      color: #999999;
    }
    .oItem-c {
      display: flex;
      justify-content: space-between;
      .item {
        width: 200px;
        height: 200px;
        img {
          width: 100%;
          height: 100%;
        }
        border-radius: 10px;
      }
    }
    .oItem-b {
      font-size: 32px;
      width: calc(100% - 60px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 30px;
      span {
        color: salmon;
      }
    }
  }
}
</style>
